const config = useRuntimeConfig()

export function useSiteHead() {
  useHead({
    title: 'UG Investment Advisors Ltd',
    bodyAttrs: {
      class: 'body-layout--default'
    },
    htmlAttrs: {
      lang: useHtmlLang(),
    },
    // https://www.geeksforgeeks.org/whats-the-difference-between-meta-name-and-meta-property/
    // Note 只能使用 <meta property>，不能用 name
    meta: [
      {
        property: 'og:url',
        content: (new URL('/', config.public.baseUrl)).href
      },
      {
        property: 'og:image',
        content: (new URL('/ogimage.png', config.public.baseUrl)).href
      },
    ]
  })
}